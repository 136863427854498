export const QUERY_KEYS = {
  ACCESSES: "ACCESSES",
  USER: "USER",
  LANDING_TARIFFS: "LANDING_TARIFFS",
  LANDING_NEWS: "LANDING_NEWS",
  LANDING_CITIES: "LANDING_CITIES",
  DRIVERS_LOCATION: "DRIVERS_LOCATION",
  DRIVERS: "DRIVERS",
  PARKS_SHORT: "PARKS_SHORT",
  PARKS: "PARKS",
  PARKS_DETAIL: "PARKS_DETAIL",
  LANDING_PARK_APPLICATION: "LANDING_PARK_APPLICATION",
  LANDING_DRIVER_APPLICATION: "LANDING_DRIVER_APPLICATION",
  PAYMENTS_SAVE: "PAYMENTS_SAVE",

  ANTI_FRAUDS: "ANTI_FRAUDS",
  ANTI_FRAUDS_REASON: "ANTI_FRAUDS_REASON",
  ANTI_FRAUDS_SETTINGS: "ANTI_FRAUDS_SETTINGS",

  CONFIGS: "CONFIGS",
  CITY_FEES: "CITY_FEES",
  PARK_FEES: "PARK_FEES",

  REPORTS: "REPORTS",
  PAYMENTS: "PAYMENTS",
  REFUND_HISTORY: "REFUND_HISTORY",
  ACCOUNT_REPORTS: "ACCOUNT_REPORTS",

  PROMOTIONS: "PROMOTIONS",
  PROMOTION_DETAILS: "PROMOTION_DETAILS",
  PROMOTION_REPORT: "PROMOTION_REPORT",
  RIDE_PROMOTION_REPORTS: "RIDE_PROMOTION_REPORTS",
  PROMOTION_PARTICIPANTS: "PROMOTION_PARTICIPANTS",
  RIDERS_REFERRAL_PROMOTION_PARTICIPANTS:
    "RIDERS_REFERRAL_PROMOTION_PARTICIPANTS",
  DRIVERS_REFERRAL_PROMOTION_PARTICIPANTS:
    "DRIVERS_REFERRAL_PROMOTION_PARTICIPANTS",
  REFERRAL_PROMOTION_REPORTS: "REFERRAL_PROMOTION_REPORTS",
  RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS:
    "RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS",
  PROMOTION_PARKS_PARTICIPANTS: "PROMOTION_PARKS_PARTICIPANTS",
  RIDE_PROMOTION_PARTICIPANTS: "PROMOTION_PARKS_PARTICIPANTS",
  CITIES: "CITIES",
  SEND_CODE: "SEND_CODE",
  VERIFY_CODE: "VERIFY_CODE",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  TARIFFS: "TARIFFS",
  TARIFFS_BY_ID: "TARIFFS_BY_ID",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST: "SUPPORT_RIDERS_CHAT_MESSAGE_LIST",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT:
    "SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID:
    "SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST: "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT:
    "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID:
    "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID",
  NEWS: "NEWS",
  NEWS_DETAILS: "NEWS_DETAILS",
  DRIVER_APPLICATIONS: "DRIVER_APPLICATIONS",
  DRIVER_APPLICATIONS_DETAIL: "DRIVER_APPLICATIONS_DETAIL",
  LANDING_DRIVER_APPLICATIONS: "LANDING_DRIVER_APPLICATIONS",
  DASHBOARD_USERS: "DASHBOARD_USERS",
};
