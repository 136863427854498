import { useId } from "@mantine/hooks";
import { Field } from "formik";
import React from "react";

import { Typography } from "@components/Elements";

type Props = {
  name: string;
  placeholder: string;
  label?: string;
  error?: string | undefined;
  touched?: boolean | undefined;
  readOnly?: boolean;
  className?: string;
  inputClassName?: string;
  as?: string;
  required?: boolean;
  description?: string;
  limit?: number;
};

export const FormRow: React.FC<Props> = ({
  name,
  placeholder,
  label,
  error,
  touched,
  readOnly = false,
  as = "input",
  className,
  inputClassName,
  required,
  description,
  limit,
}) => {
  const id = useId();
  const hasError = touched && error;

  const baseClasses =
    "h-[42px] px-[14px] text-body-1-a placeholder-neutral-text-400 border border-neutral-border-300 w-full rounded-[4px] focus:outline-none focus:ring-1 focus:ring-blue-600";

  const errorClasses = hasError
    ? "text-error ring-error border-negative-bg-500"
    : "";

  const combinedClassName = `${baseClasses} ${inputClassName} ${errorClasses}`;

  return (
    <div
      className={`flex flex-col items-start justify-start w-full relative ${className}`}
    >
      {label && (
        <label
          htmlFor={id}
          className="text-neutral-text-800 text-caption-b font-medium pb-[4px] flex flex-row"
        >
          {label}

          {required && <span className="text-error ml-1"> *</span>}
        </label>
      )}
      <Field
        id={id}
        as={as}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        className={combinedClassName}
        required={required}
        maxLength={limit}
      />
      {description && (
        <p className="text-neutral-text-600 text-caption-b mt-[5px] font-medium">
          {description}
        </p>
      )}
      {hasError ? (
        <Typography
          variant={"caption-a"}
          color={"negative-500"}
          className={"mt-[5px]"}
        >
          {error}
        </Typography>
      ) : null}
    </div>
  );
};
