/**
 * Сервисы для reports
 */

import { appConfig } from "@api/config";

const srcDashboard = appConfig.api.dashboard;

export const ridersPath = {
  clean_riders_debt: (rider_id: string) =>
    `${srcDashboard}/riders/${rider_id}/clear_debt/`,
};
